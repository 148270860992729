/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useForceUpdate } from '@mantine/hooks';

import { useAtom } from 'jotai';

import { base64Encode } from '~/utils/base64Encode';
import { decodeJwt } from '~/utils/decode';
import { publish, subscribe } from '~/utils/event';

import { AccessToken } from '~/domain/models/accessToken';

// import { useSettingsMutators } from '~/infrastructures/recoil/useSettingsState';
// import { useUserMutators } from '~/infrastructures/recoil/useUserState';
import Router from '~/routes';

import { axiosInstance as axios } from './axios';
import { bellStateAtom, playSound, soundBuffers } from "./sound";

import type { ISettings, ITenantSettings, IUserSettings } from '~/types/application';

const Page = () => {
  // const { setAccessToken, reset } = useUserMutators();
  // const { setSettings, resetSettings, setTenantSettings, setUserSettings } = useSettingsMutators();
  const forceUpdate = useForceUpdate();

  const [state, toggle] = useAtom(bellStateAtom);

  useEffect(() => {
    let volume = 0;
    switch (state) {
      case 'LOW':
        volume = -0.8;
        break;
      case 'MEDIUM':
        volume = -0.4;
        break;
      case 'HIGH':
        volume = 0.0
        break;
    }
    const handleButtonClicked = () => playSound(soundBuffers.buttonClicked, volume);
    const handleCompleted = () => playSound(soundBuffers.completed, volume);
    const handleConditionBatchRegistration = () => playSound(soundBuffers.conditionBatchRegistration, volume);
    const handleConditionYearmonthBatch = () => playSound(soundBuffers.conditionYearmonthBatch, volume);
    const handleExtracted = () => playSound(soundBuffers.extracted, volume);
    const handlePosterDataCreated = () => playSound(soundBuffers.posterDataCreated, volume);
    const handlePosterBatchMailSend = () => playSound(soundBuffers.posterBatchMailSend, volume);
    const handleWorkplanCompleted = () => playSound(soundBuffers.workplanCompleted, volume);
    const handleWorkplanExcelOut = () => playSound(soundBuffers.workplanExcelOut, volume);
    const handleWorkplanOutput = () => playSound(soundBuffers.workplanOutput, volume);

    const action = (state: string | null) => {
      window.removeEventListener('soundButtonClicked', handleButtonClicked);
      window.removeEventListener('soundCompleted', handleCompleted);
      window.removeEventListener('soundConditionBatchRegistration', handleConditionBatchRegistration);
      window.removeEventListener('soundConditionYearmonthBatch', handleConditionYearmonthBatch);
      window.removeEventListener('soundExtracted', handleExtracted);
      window.removeEventListener('soundPosterDataCreated', handlePosterDataCreated);
      window.removeEventListener('soundPosterBatchMailSend', handlePosterBatchMailSend);
      window.removeEventListener('soundWorkplanCompleted', handleWorkplanCompleted);
      window.removeEventListener('soundWorkplanExcelOut', handleWorkplanExcelOut);
      window.removeEventListener('soundWorkplanOutput', handleWorkplanOutput);

      if (state === 'LOW' || state === 'MEDIUM' || state === 'HIGH') {
        window.addEventListener('soundButtonClicked', handleButtonClicked);
        window.addEventListener('soundCompleted', handleCompleted);
        window.addEventListener('soundConditionBatchRegistration', handleConditionBatchRegistration);
        window.addEventListener('soundConditionYearmonthBatch', handleConditionYearmonthBatch);
        window.addEventListener('soundExtracted', handleExtracted);
        window.addEventListener('soundPosterDataCreated', handlePosterDataCreated);
        window.addEventListener('soundPosterBatchMailSend', handlePosterBatchMailSend);
        window.addEventListener('soundWorkplanCompleted', handleWorkplanCompleted);
        window.addEventListener('soundWorkplanExcelOut', handleWorkplanExcelOut);
        window.addEventListener('soundWorkplanOutput', handleWorkplanOutput);
      }
    }
    const handleToggle = (evt : Event) => {
        evt.stopPropagation()
        toggle();
        action(state);
    };

    window.addEventListener('ringToggle', handleToggle);

    action(state);

    return () => {
      window.removeEventListener('soundButtonClicked', handleButtonClicked);
      window.removeEventListener('soundCompleted', handleCompleted);
      window.removeEventListener('soundConditionBatchRegistration', handleConditionBatchRegistration);
      window.removeEventListener('soundConditionYearmonthBatch', handleConditionYearmonthBatch);
      window.removeEventListener('soundExtracted', handleExtracted);
      window.removeEventListener('soundPosterDataCreated', handlePosterDataCreated);
      window.removeEventListener('soundPosterBatchMailSend', handlePosterBatchMailSend);
      window.removeEventListener('soundWorkplanCompleted', handleWorkplanCompleted);
      window.removeEventListener('soundWorkplanExcelOut', handleWorkplanExcelOut);
      window.removeEventListener('soundWorkplanOutput', handleWorkplanOutput);
      window.removeEventListener('ringToggle', handleToggle);
    }
  }, [state]);

  useEffect(() => {
    subscribe('login', (evt: Event) => {
      const token = (evt as CustomEvent<string>).detail;
      // setAccessToken(token);
      forceUpdate();
    });
    subscribe('logout', (evt: Event) => {
      // reset();
      forceUpdate();
    });
    subscribe('logout', (_evt: Event) => {
      void axios.post('/api/tracking/v2/clear');
      publish<{ messageType: string; message: string }>('send', { messageType: 'user', message: '' });
      publish<IWatchdogMessage>('watchdog', {
        instanceId: window.instanceId ?? '',
        clientId: localStorage.getItem('client-id') ?? '',
        fingerprint: localStorage.getItem('fingerprint') ?? '',
        userId: null,
        userCode: null,
        userName: null,
        tenantId: null,
        functionHandle: 'logout',
        functionName: 'ログアウト',
        targetYearmonth: '',
        sectionCode: '',
        targetId: '',
      });

      const msg = {
        messageType: 'applicationLogging',
        functionHandle: 'logout',
        functionName: 'ログアウト',
        actionHandle: 'logout',
        actionName: `ログアウト`,
        actionNotice: '［ログアウト］選択',
        foregroundColor: '#000',
        backgroundColor: '',
        message: `ログアウト ${window.tenantHandle} ${window.userCode} ${window.userName}`,
      };

      void axios.put('/api/applicationLogging/v2', msg).then(() => {
        axios.interceptors.request.use(config => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          config.headers = {
            'x-instance-id': window.instanceId,
            'x-client-id': localStorage.getItem('client-id')!,
            'x-fingerprint': localStorage.getItem('fingerprint')!,
            authorization: '',
          };

          return config;
        });
        window.accessToken = undefined;
        window.userCode = undefined;
        window.userId = undefined;
        window.userName = undefined;
        window.userAbbreviation = undefined;
        window.permissions = [];
        window.roles = [];
        window.sections = [];
        window.roleName = '';
        window.settings.user = {} as IUserSettings;

        // resetSettings();

        const classes = Array.from(window.document.documentElement.classList).filter(f => f.startsWith('role-'));
        classes.forEach(c => window.document.documentElement.classList.remove(c));

        void (async () => {
          if (window.navigation != undefined) {
            await window.navigation.navigate('/login', { state: {}, info: 'signin', history: 'replace' }).finished;
          } else {
            window.location.href = '/login';
          }
        })();
      });
    });

    subscribe('kickOut', (_evt: Event) => {
      void axios.get('/api/tracking/v2/clear');
      publish<{ messageType: string; message: string }>('send', { messageType: 'user', message: '' });
      publish<IWatchdogMessage>('watchdog', {
        instanceId: window.instanceId ?? '',
        clientId: localStorage.getItem('client-id') ?? '',
        fingerprint: localStorage.getItem('fingerprint') ?? '',
        userId: null,
        userCode: null,
        userName: null,
        tenantId: null,
        functionHandle: 'logout',
        functionName: 'ログアウト',
        targetYearmonth: '',
        sectionCode: '',
        targetId: '',
      });
      const msg = {
        messageType: 'applicationLogging',
        functionHandle: 'logout',
        functionName: 'ログアウト',
        actionHandle: 'kickOut',
        actionName: `強制ログアウト`,
        actionNotice: 'ログイン中ユーザー一覧から［強制ログアウト］選択',
        foregroundColor: '#fff',
        backgroundColor: '#f00',
        message: `強制ログアウト ${window.tenantHandle} ${window.userCode} ${window.userName}`,
      };
      void axios.put('/api/applicationLogging/v2', msg).then(() => {
        axios.interceptors.request.use(config => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          config.headers = {
            'x-instance-id': window.instanceId,
            'x-client-id': localStorage.getItem('client-id')!,
            'x-fingerprint': localStorage.getItem('fingerprint')!,
            authorization: '',
          };

          return config;
        });
        window.accessToken = undefined;
        window.userCode = undefined;
        window.userId = undefined;
        window.userName = undefined;
        window.userAbbreviation = undefined;
        window.permissions = [];
        window.roles = [];
        window.sections = [];
        window.roleName = '';
        window.tenantId = undefined;
        window.tenantHandle = undefined;
        window.tenantName = undefined;
        window.settings.user = {} as IUserSettings;

        // resetSettings();

        const classes = Array.from(window.document.documentElement.classList).filter(f => f.startsWith('role-'));
        classes.forEach(c => window.document.documentElement.classList.remove(c));

        void (async () => {
          if (window.navigation != undefined) {
            await window.navigation.navigate('/login', { state: {}, info: 'signin', history: 'replace' }).finished;
          } else {
            window.location.href = '/login';
          }
        })();
      });
    });

    subscribe('login', (evt: Event) => {
      const token = (evt as CustomEvent<string>).detail;
      publish<{ messageType: string; message: string }>('send', { messageType: 'user', message: token });

      const decoded: AccessToken = decodeJwt(token);
      console.dir(decoded);

      if (decoded.exp < Math.floor(Date.now() / 1000)) {
        void (async () => {
          if (window.navigation !== undefined) {
            await window.navigation.navigate('/login', { state: {}, info: 'signin', history: 'replace' }).finished;
          } else {
            window.location.href = '/login';
          }
        })();
      }

      void axios
        .get<{ tenantId: string; tenantHandle: string; tenantName: string; tenantSettings: ITenantSettings }>(
          `/api/settings/v2/${decoded.tenantId}/tenant`
        )
        .then(res => {
          window.settings = {} as ISettings;
          if (res.status == 200 && res.data != null) {
            window.tenantId = res.data.tenantId;
            window.tenantHandle = res.data.tenantHandle;
            window.tenantName = res.data.tenantName;
            window.settings.tenant = res.data.tenantSettings;
            // setTenantSettings(res.data.tenantSettings);
          } else {
            window.settings.tenant = {} as ITenantSettings;
            // resetSettings();
            console.log('テナント設定の取得に失敗しました。');
            void (async () => {
              if (window.navigation !== undefined) {
                await window.navigation.navigate('/login', { state: {}, info: 'signin', history: 'replace' }).finished;
              } else {
                window.location.href = '/login';
              }
            })();
          }
        })
        .catch(_err => {
          window.settings.tenant = {} as ITenantSettings;
          // resetSettings();
          console.log('テナント設定の取得に失敗しました。');
          void (async () => {
            if (window.navigation !== undefined) {
              await window.navigation.navigate('/login', { state: {}, info: 'signin', history: 'replace' }).finished;
            } else {
              window.location.href = '/login';
            }
          })();
        });

      window.tenantId = decoded.tenantId;
      window.tenantHandle = decoded.tenantHandle;
      window.tenantName = decoded.tenantName;
      window.permissions = decoded.permissions;
      window.roles = decoded.roles;
      window.sections = decoded.sections;
      window.userId = decoded.sub;
      window.userCode = decoded.handle;
      window.userName = decoded.name;
      window.userAbbreviation = decoded.nickname;
      window.roleName = window.roles.includes('r_sysadmin')
        ? 'システム管理者'
        : window.roles.includes('r_admin')
          ? '計画管理者'
          : window.roles.includes('r_user')
            ? '一般利用者'
            : 'なし';

      window.settings.user = {} as IUserSettings;
      // setUserSettings({} as IUserSettings);

      const classes = decoded.roles.map(m => `role-${m.substring(2)}`);
      classes.forEach(c => window.document.documentElement.classList.add(c));

      axios.interceptors.request.use(async config => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config.headers = {
          'x-instance-id': window.instanceId,
          'x-client-id': localStorage.getItem('client-id')!,
          'x-fingerprint': localStorage.getItem('fingerprint')!,
          // authorization: `Bearer ${token}`,
          'x-tenant-id': window.tenantId,
          'x-user-id': decoded.sub,
          'x-user-handle': decoded.handle,
          'x-user-name': (await base64Encode(decoded.name)) as string,
          'x-user-abbrev': (await base64Encode(decoded.nickname ?? '')) as string,
        };

        return config;
      });
    });

    if (window.accessToken) {
      publish('login', window.accessToken);
    }

    setTimeout(() => {
      void axios.get<string>('/api/version/implementation', { cache: false }).then(res => {
        window.implVersion = res.data;
      });
      void axios.get<string>('/api/version/specification', { cache: false }).then(res => {
        window.specVersion = res.data;
      });
    }, 5000);
  }, []);

  return <Router />;
};

export default Page;
