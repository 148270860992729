import React, { memo } from "react";
import { Link } from "react-router-dom";

import { ActionIcon, Burger, Menu } from "@mantine/core";
import { useFullscreen } from "@mantine/hooks";
import {
  IconBellOff,
  IconBellRinging,
  IconLogout,
  IconUserCircle,
  IconWindowMaximize,
  IconWindowMinimize
} from "@tabler/icons-react";

import { useAtom, useAtomValue } from "jotai";

import { publish } from "~/utils/event";

import classes from "~/layouts/Default.module.css";
import { bellStateAtom } from "~/sound";
import clsx from "clsx";

export const Header = memo(
  (props: {
    handler: [boolean, { readonly open: () => void; readonly close: () => void; readonly toggle: () => void }];
  }) => {
    const [menuOpened, menuHandlers] = props.handler;
    const { toggle, fullscreen } = useFullscreen();

    const [state]  = useAtom(bellStateAtom)
    // const [ringed, handlers] = useDisclosure(true, {
    //   onOpen: () => publish('ringOn'),
    //   onClose: () => publish('ringOff'),
    // });

    return (
      <header className={classes.header}>
        <div className="flex flex-row items-center justify-between">
          <div className="flex cursor-pointer select-none flex-row items-center justify-start">
            <Burger
              opened={menuOpened}
              size="sm"
              color="gray"
              className="ml-2 mr-5 p-0"
              onClick={menuHandlers.toggle}
            />
            <Link to="/">
              <img
                // srcSet={`/api/images/${window.tenantId}/title.webp`}
                src={`/api/images/${window.tenantId}/title.png`}
                style={{ width: '300px' }}
                alt="home"
              />
            </Link>
          </div>

          <div className="flex flex-row items-center justify-start gap-2">
            <div className="hidden flex-row items-center justify-start sm:flex">
              <div className="mr-4 font-body text-[9pt] text-gray-800">{window.tenantName}</div>
              <IconUserCircle size={18} />
              <Menu shadow="md" trigger="hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <div className="cursor-pointer select-none font-body text-[9pt] text-gray-800">
                    {window.userName}（{window.roleName}）
                  </div>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={<IconLogout size={14} />}
                    onClick={() => {
                      const data = {
                        tenantId: window.tenantId,
                        tenantHandle: window.tenantHandle,
                        tenantName: window.tenantName,
                        userId: window.userId,
                        userCode: window.userCode,
                        userName: window.userName,
                      };
                      publish('logout', data);
                    }}>
                    <span className="cursor-pointer select-none font-body text-[9pt] text-gray-800">ログアウト</span>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
            <div className="block sm:hidden">
              <Menu shadow="md" trigger="hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <IconUserCircle size={18} />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>{window.tenantName}</Menu.Label>
                  <Menu.Label>
                    {window.userName}（{window.roleName}）
                  </Menu.Label>
                  <Menu.Divider />
                  <Menu.Item
                    leftSection={<IconLogout size={14} />}
                    onClick={() => {
                      const data = {
                        tenantId: window.tenantId,
                        tenantHandle: window.tenantHandle,
                        tenantName: window.tenantName,
                        userId: window.userId,
                        userCode: window.userCode,
                        userName: window.userName,
                      };
                      publish('logout', data);
                    }}>
                    <span className="font-body text-[9pt] text-gray-800">ログアウト</span>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
            <ActionIcon
              className="mb-1 mt-1"
              color="gray"
              variant="outline"
              aria-label="Ringging"
              title={state === 'LOW' ? '小' : state === 'MEDIUM' ? '中' : state === 'HIGH' ? '大' : '無'}
              onClick={() => publish('ringToggle')}>
              {state === 'LOW' || state === 'MEDIUM' || state === 'HIGH' ? (
                <IconBellRinging className={clsx(
                  {
                    'text-blue-200' : state === 'LOW',
                    'text-blue-500' :  state === 'MEDIUM',
                    'text-blue-900' : state === 'HIGH',
                  }
                )} style={{ width: '70%', height: '70%' }} stroke={1.5} />
              ) : (
                <IconBellOff color="red" style={{ width: '70%', height: '70%' }} stroke={1.5} />
              )}
            </ActionIcon>
            <ActionIcon
              className="mb-1 mr-8 mt-1"
              color="gray"
              variant="outline"
              aria-label="Fullscreen"
              onClick={toggle}>
              {fullscreen ? (
                <IconWindowMinimize color="black" style={{ width: '70%', height: '70%' }} stroke={1.5} />
              ) : (
                <IconWindowMaximize color="black" style={{ width: '70%', height: '70%' }} stroke={1.5} />
              )}
            </ActionIcon>
          </div>
        </div>
      </header>
    );
  }
);
