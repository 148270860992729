import { memo, ReactNode, useEffect } from "react";

import { Drawer, ScrollArea } from "@mantine/core";

import { useQueryClient } from "@tanstack/react-query";

import classes from "~/layouts/Default.module.css";

// eslint-disable-next-line react/display-name
export const Navbar = memo(
  (props: {
    links: ReactNode;
    handler: [boolean, { readonly open: () => void; readonly close: () => void; readonly toggle: () => void }];
  }) => {
    const [menuOpened, menuHandlers] = props.handler;

    const queryClient = useQueryClient();

    useEffect(() => {
      void queryClient.cancelQueries();
      void queryClient.clear();
    }, [menuOpened]);

    return (
      <Drawer
        zIndex={1000}
        closeOnClickOutside={true}
        closeOnEscape={true}
        withCloseButton={false}
        withOverlay={true}
        position="left"
        size={250}
        overlayProps={{ backgroundOpacity: 0.25, blur: 2 }}
        transitionProps={{ transition: 'slide-right', duration: 400, timingFunction: 'ease' }}
        opened={menuOpened}
        classNames={{ content: 'relative top-10' }}
        onClose={menuHandlers.close}>
        <nav className={classes.navbar}>
          <ScrollArea className={classes.links}>
            <div className={classes.linksInner}>{props.links}</div>
          </ScrollArea>
        </nav>
      </Drawer>
    );
  }
);
