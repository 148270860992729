import React, { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useClickOutside, useDisclosure, useFullscreen } from "@mantine/hooks";
import {
  IconAdjustments,
  IconCalendarStats,
  IconDatabase,
  IconListCheck,
  IconNotes,
  IconPresentationAnalytics
} from "@tabler/icons-react";

import { clsx } from "clsx";

import { Footer } from "~/layouts/Footer";
import { Header } from "~/layouts/Header";
import { Navbar } from "~/layouts/Navbar";

import PosterIcon from "~/features/poster/common/PosterIcon";

import WebVitals from "~/features/WebVitals";

import { LinksGroup } from "~/components/ui/aspi/NavbarLinksGroup/NavbarLinksGroup";

import classes from "./Default.module.css";


export default () => {
  const [menuOpened, menuHandlers] = useDisclosure(false);

  const roles = new Set(window.roles);

  const rSysAdmin = roles.has('r_sysadmin');
  const rAdmin = roles.has('r_admin') && !rSysAdmin;
  const rUser = roles.has('r_user') && !rAdmin && !rSysAdmin;

  const showVehicle = window.settings.tenant.showVehicle;

  const [dropdown, setDropdown] = useState<any | null>(null);
  const [control, setControl] = useState<any | null>(null);
  useClickOutside(() => menuHandlers.close(), ['mouseup', 'touchend'], [control, dropdown]);

  const menuDataSysAdmin = useMemo(
    () =>
      (window.settings != null && window.permissions != null
        ? [
            {
              label: '契約情報管理',
              icon: IconNotes,
              initiallyOpened: false,
              links: [
                { label: '契約データ', link: '/contract' },
                window.settings.tenant.yearlyContractEnabled === true
                  ? { label: '物件別契約情報管理', link: '/yearlyContract' }
                  : null,
              ].filter(f => f != null),
            },
            {
              label: '作業計画管理',
              icon: IconCalendarStats,
              links: [
                { label: '稼働予定', link: '/planning/crew' },
                { label: '計画作成用データ確認', link: '/planning/work' },
                { label: '作業計画確認', link: '/planning/confirmation' },
              ],
            },
            window.settings.tenant.poster && window.permissions.find(f => 'p_poster') != null
              ? {
                  label: 'お知らせ管理',
                  icon: PosterIcon,
                  links: [
                    { label: 'チラシレイアウト確認', link: '/poster/preview', disabled: false },
                    { label: 'チラシフォーム管理', link: '/poster/template', disabled: false },
                    { label: 'お知らせ状況管理', link: '/poster/project', disabled: false },
                  ],
                }
              : null,
            window.settings.tenant.project != null && window.permissions.find(f => 'p_project') != null
              ? {
                  label: '工程管理',
                  icon: IconListCheck,
                  links: [{ label: '工程一覧', link: '/project' }],
                }
              : null,
            { label: 'データ分析', icon: IconPresentationAnalytics, disabled: true },
            {
              label: 'マスタ管理',
              icon: IconDatabase,
              links: [
                { label: '物件マスタ', link: '/maintenance/building', disabled: false },
                { label: '取引先マスタ', link: '/maintenance/customer', disabled: false },
                showVehicle ? { label: '車両管理マスタ', link: '/maintenance/vehicle', disabled: false } : null,
                { label: 'ユーザマスタ', link: '/maintenance/user', disabled: false },
                { label: '部門マスタ', link: '/maintenance/section', disabled: false },
                { label: '作業マスタ', link: '/maintenance/operation', disabled: false },
                { label: '作業分類マスタ', link: '/maintenance/operationCategory', disabled: false },
                { label: '資格・技能マスタ', link: '/maintenance/qualification', disabled: false },
              ].filter(f => f != null),
            },
            {
              label: 'システム管理',
              icon: IconAdjustments,
              links: [
                { label: 'システム設定', link: '/config', disabled: false },
                { label: 'ログ管理', link: '/applicationLogging', disabled: false },
                { label: 'ログイン中ユーザー一覧', link: '/sessionMonitor', disabled: false },
                { label: 'ライセンス情報', link: '/license', disabled: false },
                // { label: 'テスト', link: '/test/timepicker', disabled: false },
              ],
            },
          ]
        : []
      ).filter(f => f != null),
    [window.settings, window.permissions]
  );

  const menuDataAdmin = useMemo(
    () =>
      (window.settings != null && window.permissions != null
        ? [
            {
              label: '契約情報管理',
              icon: IconNotes,
              initiallyOpened: true,
              links: [
                { label: '契約データ', link: '/contract' },
                window.settings.tenant.yearlyContractEnabled === true
                  ? { label: '物件別契約情報管理', link: '/yearlyContract' }
                  : null,
              ].filter(f => f != null),
            },
            {
              label: '作業計画管理',
              icon: IconCalendarStats,
              links: [
                { label: '稼働予定', link: '/planning/crew' },
                { label: '計画作成用データ確認', link: '/planning/work' },
                { label: '作業計画確認', link: '/planning/confirmation' },
              ],
            },
            window.settings.tenant.poster && window.permissions.find(f => 'p_poster') != null
              ? {
                  label: 'お知らせ管理',
                  icon: PosterIcon,
                  links: [
                    { label: 'チラシレイアウト確認', link: '/poster/preview' },
                    { label: 'チラシフォーム管理', link: '/poster/template' },
                    { label: 'お知らせ状況管理', link: '/poster/project' },
                  ],
                }
              : null,
            window.settings.tenant.project != null && window.permissions.find(f => 'p_project') != null
              ? {
                  label: '工程管理',
                  icon: IconListCheck,
                  links: [{ label: '工程一覧', link: '/project' }],
                }
              : null,
            { label: 'データ分析', icon: IconPresentationAnalytics, disabled: true },
            {
              label: 'マスタ管理',
              icon: IconDatabase,
              links: [
                { label: '物件管理', link: '/maintenance/building' },
                { label: '取引先管理', link: '/maintenance/customer' },
                showVehicle ? { label: '車両管理マスタ', link: '/maintenance/vehicle', disabled: false } : null,
              ].filter(f => f != null),
            },
          ]
        : []
      ).filter(f => f != null),
    [window.settings, window.permissions]
  );

  const menuDataUser = useMemo(
    () =>
      (window.settings != null && window.permissions != null
        ? [
            {
              label: '契約情報管理',
              icon: IconNotes,
              initiallyOpened: true,
              links: [
                { label: '契約データ', link: '/contract' },
                window.settings.tenant.yearlyContractEnabled == true
                  ? { label: '物件別契約情報管理', link: '/yearlyContract' }
                  : null,
              ].filter(f => f != null),
            },
            {
              label: '作業計画管理',
              icon: IconCalendarStats,
              initiallyOpened: true,
              links: [{ label: '作業計画確認', link: '/planning/confirmation', disabled: false }],
            },
            window.settings.tenant.poster && window.permissions.find(f => 'p_poster') != null
              ? {
                  label: 'お知らせ管理',
                  icon: PosterIcon,
                  links: [
                    { label: 'チラシレイアウト確認', link: '/poster/preview', disabled: false },
                    { label: 'チラシフォーム管理', link: '/poster/template', disabled: false },
                    { label: 'お知らせ状況管理', link: '/poster/project', disabled: false },
                  ],
                }
              : null,
            window.settings.tenant.project != null && window.permissions.find(f => 'p_project') != null
              ? {
                  label: '工程管理',
                  icon: IconListCheck,
                  links: [{ label: '工程一覧', link: '/project' }],
                }
              : null,
            { label: 'データ分析', icon: IconPresentationAnalytics, disabled: true },
            {
              label: 'マスタ管理',
              icon: IconDatabase,
              links: [
                { label: '物件管理', link: '/maintenance/building' },
                { label: '取引先管理', link: '/maintenance/customer' },
                showVehicle ? { label: '車両管理マスタ', link: '/maintenance/vehicle', disabled: false } : null,
              ].filter(f => f != null),
            },
          ]
        : []
      ).filter(f => f != null),
    [window.settings, window.permissions]
  );

  const links = useMemo(
    () => (
      <>
        <div className={clsx({ block: rSysAdmin, hidden: !rSysAdmin })}>
          {menuDataSysAdmin.map(item => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LinksGroup
              {...item}
              key={item?.label ?? ''}
              onClick={e => {
                menuHandlers.close();
                //setTimeout(menuHandlers.close, 0);
              }}
            />
          ))}
        </div>
        <div className={clsx({ block: rAdmin, hidden: !rAdmin })}>
          {menuDataAdmin.map(item => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LinksGroup
              {...item}
              key={item?.label ?? ''}
              onClick={e => {
                menuHandlers.close();
                // setTimeout(menuHandlers.close, 0);
              }}
            />
          ))}
        </div>
        <div className={clsx({ block: rUser, hidden: !rUser })}>
          {menuDataUser.map(item => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LinksGroup
              {...item}
              key={item?.label ?? ''}
              onClick={e => {
                menuHandlers.close();
                //setTimeout(menuHandlers.close, 0);
              }}
            />
          ))}
        </div>
      </>
    ),
    [rSysAdmin, rAdmin, rUser, menuDataSysAdmin, menuDataAdmin, menuDataUser]
  );

  return (
    <div className={classes.main}>
      <Header handler={[menuOpened, menuHandlers]} />
      <Navbar links={links} handler={[menuOpened, menuHandlers]} />
      <main className="p-2" style={{ height: 'calc( 100vh - 52px' }}>
        <Outlet />
      </main>
      <Footer />
      <WebVitals />
    </div>
  );
};
